<template>
  <v-container>
    <v-row>
      <!-- Main content -->
      <v-col
        cols="md-8 banner-panel"
        style="margin-bottom: 12px; margin-top: 20px"
        class="secondary"
      >
        <!-- Image big here  -->
        <v-img
          alt="ipNX | Creating Possibilities"
          style="width: 100%; height: 351px"
          src="../assets/ipnx-banner.png"
        ></v-img>
      </v-col>
      <!-- ./Main content -->
      <v-col cols="md-4" style="margin-bottom: 12px; margin-top: 20px">
        <v-card style="box-shadow: none; height: 351px; padding-bottom: 50px">
          <v-card-title>
            <v-layout align-center justify>
              <h2>LOG IN</h2>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <p>Login with your customer number and password</p>
            <v-form>
              <div class="form-group">
                <v-text-field
                  required
                  outline
                  class="form-control"
                  label="Customer Number"
                  type="text"
                  v-model="username"
                ></v-text-field>
                <v-text-field
                  required
                  outline
                  hide-details
                  class="form-control"
                  label="Password"
                  type="password"
                  v-model="password"
                ></v-text-field>
              </div>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#d73630"
              style="width: -webkit-fill-available; color: white"
              large
              :disabled="!isValid"
              @click="login"
            >
              Sign in
            </v-btn>
          </v-card-actions>
          <div class="password-reset">
            <a href="/forgot-password"> Forgot your password? </a>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">

  export default {
    name: 'login',
    components: {
    },
    data: () => ({
      password: '',
      username: ''
    }),
    computed: {
      isValid() {
      return this.password.length && this.username.length >= 5
      }
    },
    methods: {
      login() {

        console.log("enetered:");

        const data = {
        username: this.username,
        password: this.password
      }
      this.$http
        .post('/login', JSON.stringify(data))
        .then((resp) => {

          console.log("enetered:2");          

          console.log("resp.data:", resp.data);

          this.loading = false
          if (resp.data.status == false) {
            // this.snack(resp.data.message, 'error')
          //  alert('We run into an error. Please check your inputs & try again.')
          this.$root.NotificationBar.show({icon: "mdi-alert", message: 'We run into an error. Please check your inputs & try again.', showSnackbar: true})
           return false;
          }
          else {

            const customer = {
              clientID: resp.data.clientID,
              auth_token: resp.data.auth_token,
              email: resp.data.email, 
              first_time: resp.data.active
            }
            localStorage.setItem('customer', JSON.stringify(customer))

            resp.data.active == 1 ? this.$router.push('/change-password') : this.$router.push('/dashboard');
          }
        })
        .catch(() => {

          console.log("enetered:3");
          this.loading = false
          this.$root.NotificationBar.show({icon: "mdi-alert", message: 'We run into an error. Please check your inputs & try again.2', showSnackbar: true})

        })
      }
    }
  }
</script>
<style scoped>
.password-reset a {
  color: #337ab7;
  text-decoration: none;
  padding: 8px;
}
.form-control:focus-visible {
  border: 1px solid red !important;
}
</style>


<template>
  <v-container>
    <section class="header primary">
      <h1></h1>
    </section>

    <v-row>
      <!-- Main content -->
      <v-col cols="md-2" style="margin-bottom: 12px; margin-top: 20px"> </v-col>
      <!-- ./Main content -->
      <v-col cols="md-6">
        <v-card style="box-shadow: none">
          <v-card-title style="word-break: normal">
            <v-layout align-center>
              <h2 style="text-align: center">Change Password</h2>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <p v-if="this.first_time == 1">
              Please change your password after your first login.
            </p>
            <p v-else>Please enter your current password and a new password</p>
            <v-form>
              <v-text-field
                required
                outline
                label="Old Password"
                type="password"
                v-model="current_password"
              ></v-text-field>
              <v-text-field
                required
                outline
                label="New Password"
                type="password"
                v-model="new_password"
              ></v-text-field>
              <v-text-field
                required
                outline
                label="Confirm Password"
                type="password"
                v-model="confirm_password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#d73630"
              style="width: -webkit-fill-available; color: white"
              large
              :disabled="!isValid"
              @click="send_reset"
            >
              Change Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- Main content -->
      <v-col cols="md-2" style="margin-bottom: 12px; margin-top: 20px"> </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
 let customer = JSON.parse(localStorage.getItem("customer"));
export default {
    name: 'ChangePassword',
    data: () => ({
      loading: true,
      current_password: '',
      new_password: '',
      confirm_password: '',
      first_time: ''
    }),
    mounted () {
      //  Check whether customer exists. 
      this.loading = false;  
      localStorage.getItem("customer") ? this.first_time = customer.first_time : this.$router.push('/login');
  },
    computed: {
      isValid() {
      return this.current_password.length && this.new_password.length && this.confirm_password.length  >= 3 && this.new_password == this.confirm_password
      }
    },
    methods: {
      send_reset() {
        const customer = JSON.parse(localStorage.getItem("customer"));
        const headers = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + customer.auth_token,
      }
        const data = {
         current_password: this.current_password,
         new_password: this.new_password,
         confirm_password: this.confirm_password,
         clientID: customer.clientID
        }
       // POST to password reset endpoint.
       this.$http
        .post('/new-password', JSON.stringify(data), {headers: headers})
        .then((resp) => {
          // TODO: Use proper API req & resp values when fixed.
          console.log(resp);
          if (resp.status != 200 )
          {
            this.$root.NotificationBar.show({icon: "mdi-alert", message: 'Please check your inputs & try again.', showSnackbar: true})
            return false;
          }
          else {
           this.$root.NotificationBar.show({icon: "mdi-check", message: 'Password change successful. Please login with new credentials', showSnackbar: true})
            // Remove customer and re authenticate.
          localStorage.removeItem('customer');
          setTimeout(this.$router.push('/login'), 3000);
          }
          
        })
        .catch((e) => {
          console.log(e);
          this.loading = false
          // this.snack('A network error occured', 'error')
          alert('Please check your inputs & try again.')
        })
      }
    }
  }
</script>
import Vue from 'vue';
import Vuetify from 'vuetify/lib/';
// import 'vuetify/src/styles';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light: {
                primary:'#f7f7f7',
                secondary: "#ffffff"
            }
        }
        
    }
})
export default vuetify;

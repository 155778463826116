import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './components/Dashboard.vue';
import InvoiceItem from './components/InvoiceItem.vue';
import Login from './components/Login.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import ChangePassword from './components/ChangePassword.vue';
import ResetPassword from './components/ResetPassword.vue';

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/dashboard',
            name: 'dashboard',
            component: Dashboard
        },
        {
            path: '/invoice/',
            name: 'invoice-item',
            component: InvoiceItem
          },
        {
          path: '/login',
          name: 'login',
          component: Login   
        },
      {
        path: '/',
        name: 'home',
        component: Dashboard
      },
        {
          path: '/forgot-password',
          name: 'forgot-password',
          component: ForgotPassword   
      },
      {
        path: '/change-password',
        name: 'change-password',
        component: ChangePassword
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword
      },
      {
        path: '/*',
        name: 'not-found',
        component: Dashboard
      }
    ]
})
<template>
  <v-container>
    <section class="header primary">
      <h1></h1>
    </section>

    <v-row>
      <!-- Main content -->
      <v-col cols="md-2" style="margin-bottom: 12px; margin-top: 20px"> </v-col>
      <!-- ./Main content -->
      <v-col cols="md-6">
        <v-card style="box-shadow: none">
          <v-card-title style="word-break: normal">
            <v-layout align-center>
              <h2 style="text-align: center">New Password</h2>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <p>Please enter your customer number and a new password</p>
            <v-form>
              <v-text-field
                required
                outline
                label="Customer Number"
                type="text"
                v-model="customer_number"
              ></v-text-field>
              <v-text-field
                required
                outline
                label="New Password"
                type="password"
                v-model="new_password"
              ></v-text-field>
              <v-text-field
                required
                outline
                label="Confirm Password"
                type="password"
                v-model="confirm_password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#d73630"
              style="width: -webkit-fill-available; color: white"
              large
              :disabled="!isValid"
              @click="reset_password"
            >
              Change Password
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <!-- Main content -->
      <v-col cols="md-2" style="margin-bottom: 12px; margin-top: 20px"> </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">

export default {
    name: 'reset-password',
    data: () => ({
     loading: true,
     new_password: '',
     confirm_password: '',
     customer_number: ''
    }),
    mounted () {
      // parse the url params
        let urlParams = new URLSearchParams(window.location.search);
        let token = urlParams.get('token');

        // Redirect if there's no token in the query params
        if (!token){
            this.$router.push('/login');
            return
        }

        const data = JSON.stringify({
            "token": token
        });
        
        this.$http
        .post('/verify-token', data)
        .then( (resp) => {
            // token verified so we can allow password reset
            // save the token & use it to push the password reset.
            if (resp.data.status) {
                // store the token for reset action.
                localStorage.setItem('token', JSON.parse(data).token);
            }
            else {
                // redirect to login
                this.$router.push('/login');
            }
        })
        .catch((err) => {
            // token unverified so redirect
            console.log("Error: "+ err);
            this.$router.push('/login');
        })
    },
    methods: {
        // Send data to change password
        reset_password() {
            const token = localStorage.getItem("token");
            const data = JSON.stringify({
                token: token,
                customer_number: this.customer_number,
                new_password: this.new_password,
                confirm_password: this.confirm_password
                });

            this.$http
            .post('/change-password', data).
            then((resp) => {

                if (resp.data.status) {
                    const message = resp.data.message;
                    this.$root.NotificationBar.show({icon: "mdi-check", message: 'Success! '+message, showSnackbar: true})
                    localStorage.removeItem("token");
                    this.$router.push('/login');
                }
                else
                {
                  console.log(resp.data);
                  this.loading = false
                  this.$root.NotificationBar.show({icon: "mdi-alert", message: 'Please check your inputs & try again.', showSnackbar: true})
                }
            })
        }
    },
    computed: {
      isValid() {
      return this.new_password.length && this.confirm_password.length && this.customer_number.length >= 6 && this.new_password == this.confirm_password;
      }
    }
}
</script>
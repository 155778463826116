<template>
  <v-container>
    <section class="header primary">
      <h1>
        <router-link :to="{ name: 'dashboard' }">
          <v-btn text>
            <v-icon left> mdi-arrow-left </v-icon>
            Back
          </v-btn>
        </router-link>
      </h1>
    </section>

    <v-row>
      <!-- Main content -->
      <v-col
        cols="md-9 sm-12"
        style="margin-bottom: 12px; margin-top: 20px"
        class="secondary"
      >
        <h4 style="margin-top: 20px; margin-bottom: 20px">
          Invoice {{ this.receipt }} - Due on {{ this.due_date }}
        </h4>
        <v-card style="box-shadow: none">
          <v-card-text>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="line_items"
              item-key="unique_item_id"
              :loading="loader"
              hide-default-footer
              class="elevation-0"
              disable-pagination
              show-select
              checkbox-color="colorCheckbox"
            >
            </v-data-table>
          </v-card-text>
          <!-- Payment modal -->
          <div>
            <v-dialog v-model="dialog" persistent max-width="fit-content">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="red"
                  id="payBtn"
                  style="float: right"
                  large
                  class="payButton"
                  :disabled="!isValidAmount()"
                >
                  Pay {{ currency(amount_due) }}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline">
                  Choose Payment Method
                </v-card-title>
                <v-card-text>
                  <v-radio-group v-model="payOptions">
                    <v-radio
                      color="red darken-3"
                      label="Pay with Wallet Balance"
                      value="balance"
                    ></v-radio>
                    <v-radio
                      color="red darken-3"
                      label="Pay with Paystack"
                      value="checkout"
                    ></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-card-actions>
                  <div class="text-center">
                    <v-btn
                      large
                      class="payButton"
                      v-if="payOptions == 'balance'"
                      @click="walletPayment"
                    >
                      Pay with Wallet
                    </v-btn>
                    <paystack
                      v-else
                      :amount="parseInt(amount_due * 100)"
                      :email="email"
                      :paystackkey="paystack_key"
                      :reference="reference"
                      :callback="processPayment"
                      :metadata="metadata"
                      class="{payButton, large}"
                      :close="close"
                    >
                      Pay with Paystack
                    </paystack>
                  </div>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <!-- ./Payment modal -->
        </v-card>
      </v-col>
      <!-- ./Main content -->
      <v-col cols="md-3 sm-12">
        <Wallet />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="js">
  import paystack from 'vue-paystack';
  import Wallet from './Wallet.vue';
  
  export default  {
    name: 'invoice-item',
    components: {
      paystack,
      Wallet
    },
    mounted () {
      const customer = JSON.parse(localStorage.getItem('customer'));
      localStorage.setItem('invoice', JSON.stringify(this.$route.params.invoice_number))
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+customer.auth_token
      }
      this.invoice = JSON.parse(localStorage.getItem('invoice'));
      const data = JSON.stringify({ 
        "clientID": customer.clientID,
        "invoice_id": this.invoice});

      this.$http
      .post('/invoice_items', data, {headers: headers})
      .then((resp) => {
        console.log(resp.data)
        // TODO: How to deal with expired token?  
        this.loader = false;
        this.receipt = resp.data.data.invoice_id;
        if (!resp.data.status) 
        {
         this.$router.push('/login');
         localStorage.removeItem('customer');
         return
        }
        this.line_items = resp.data.data.invoice_items;
        this.due_date = resp.data.data.invoice_date;
    }).catch((err) => {
      this.$root.NotificationBar.show({icon: "mdi-alert", message: 'We run into an error.', showSnackbar: true})
      console.log("ERROR "+err);
    })

    },
    data () {
      const date = new Date();
      let customer = JSON.parse(localStorage.getItem('customer'));
      return {
        receipt: '',
        dialog: false,
        payOptions: 'balance',
        selected: [],
        colorCheckbox:'#ff1414',
        singleSelect: true,
        qty: '1',
        loader: true,
        headers: [
          {
            text: 'Ref ',
            align: 'start',
            sortable: false,
            value: 'ref',
          },
          { text: 'Description', value: 'description' },
          { text: 'Unit Price (NGN)', value: 'unit_price' },
          { text: 'VAT (NGN)', value: 'tax' },
          {text: 'QTY', value: 'quantity'},
          { text: 'Amount (NGN)', value: 'amount' }       
        ],
        line_items: [],
        due_date: '',
        email: customer.email,
        paystack_key: 'pk_live_f52d8f622640f6a950b71ee45de424ff5f8ccad5',
        reference:'PSTK-IPNX-'+`${this.$route.params.invoice_number}`+'-'+date.getTime(), //PSTK-IPNX-Invoicenumber-date&time
        amount_due: '00' ,
        ref_amount: [],
        metadata: {
          'custom_fields':[
             {
              "display_name": "Customer ID",
              "variable_name": "customerID",
              "value": customer.clientID
            },
            {
              "display_name": "Invoice ID",
              "variable_name": "invoiceID",
              "value": this.$route.params.invoice_number
            },
            { "display_name": "Items Paid for",
              "variable_name": "refAmount",
              "value": {
                'refAmount': ''
              }
            }
          ]
        }     }
    },
    methods: {
       // sums up the amount_due and taxes of the selected items.
        get_selected (chosen) {
          var amount_due = chosen.reduce(function (prev_amount, curr_amount) {
            return parseFloat(prev_amount) + parseFloat(curr_amount.amount) + parseFloat(curr_amount.tax);
          },0)

          // Empty ref_amount so that we can update it with latest values from chosen
          this.ref_amount = [];

          // iterate through chosen and populate ref_amount with the latest data.
          for(let element of chosen) {
            
            this.ref_amount.push({
              tax: element.tax,
              unique_item_id: element.unique_item_id,
              item_id: element.item_id,
              amount: element.amount
            })
          }
          this.amount_due = amount_due;
          return this.amount_due;
        },
        close(){
          console.log("closing");
          this.dialog = false;
        },
        // payments made via Paystack
        processPayment(response) {
          // post to middleware to update it.
          console.log(response);
          this.$root.NotificationBar.show({icon: "mdi-check", message: 'Payment received successfully.', showSnackbar: true})
          this.$router.push('/dashboard');
        },
        walletPayment() {
          let customer = JSON.parse(localStorage.getItem('customer'));
          const headers = {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+customer.auth_token
         }
          const data = JSON.stringify({
            clientID: customer.clientID,
            invoice_id: this.receipt,
            amount: this.amount_due,
            ref_amount: this.ref_amount
          })
          console.log("Dataa request");
          console.log(data);
          this.$http.post('/pay-invoice', data, {headers: headers}
          ).then((resp) => {
            // If there's an error
            if (!resp.data.status) {
              this.$root.NotificationBar.show({icon: "mdi-alert", message: 'Error: '+resp.data.message, showSnackbar: true})
              this.dialog = false;
            }
            else {
              this.$root.NotificationBar.show({icon: "mdi-check", message: 'Payment successful', showSnackbar: true})
              this.dialog = false;
              this.$router.push('/dashboard');
            }
            
          }).catch((error) => {
            console.log('Something is wrong '+ error);
            this.$root.NotificationBar.show({icon: "mdi-alert", message: 'Something went wrong. Please try again later', showSnackbar: true})
          })

        },

        currency (amount) {
        // format string numbers into currency.
        return new Intl.NumberFormat("en-US").format(amount);
      },
      // check whether the amount is greater than 0
      isValidAmount() {
      return this.amount_due > 0 ? true : false
      }
    },
    watch: {
      // Updates metadata whenever there's a change on ref_amount data
      ref_amount: function () {
        this.metadata.custom_fields[2].value.refAmount = this.ref_amount;
      },
      // deals with the selected row.
      selected: function (n) {
        this.get_selected(n);
      }

    }
  }
</script>
<style scoped lang="scss">
.payButton {
  background-color: #ff1414 !important;
  padding: 12px;
  color: white !important;
  border-radius: 5px;
  float: right;
}
</style>
<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-show="isLoggedIn"
          class="btn-Login"
          id="btn-login"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-account-circle-outline </v-icon>
          My Account
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index">
          <a @click="clickAction(index)">
            <v-list-item-title>
              <v-icon> {{ item.icon }} </v-icon>
              {{ item.title }}
            </v-list-item-title>
          </a>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "AccountButton",
  data: () => ({
    items: [
      { title: "Change Password", icon: "mdi-account-cog-outline" },
      { title: "Logout", icon: "mdi-logout" },
    ],
    user: JSON.parse(localStorage.getItem("customer")),
  }),
  methods: {
    // determine which method to execute based on link clicked.
    clickAction(index) {
      index == 0 ? this.changePassword() : this.signout();
    },
    signout() {
      const customer = JSON.parse(localStorage.getItem("customer"));
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + customer.auth_token,
      };
      const data = JSON.stringify({ clientID: customer.clientID });

      this.$http
        .post("/logout", data, { headers: headers })
        .then(() => {
          localStorage.removeItem("customer");
          this.user = false;
          this.$router.push("/login");
        })
        .catch(() => {
          this.$root.NotificationBar.show({
            icon: "mdi-alert",
            message: "Something went wrong. Please try again later",
            showSnackbar: true,
          });
        });
    },
    changePassword() {
      this.$router.push("/change-password");
    },
    // sets user from localstorage...
    setUser() {
      console.log("getting user");
      return (this.user = JSON.parse(localStorage.getItem("customer")));
    },
  },
  mounted() {
    console.log("Mounted user ");
    this.user = JSON.parse(localStorage.getItem("customer"));
  },
  watch: {
    // when the route changes, set the user.
    $route: function () {
      console.log("routing");
      // let customer = JSON.parse(localStorage.getItem("customer"));

      return this.$route.path == "/dashboard" ? this.setUser() : false;
    },
  },
  computed: {
    isLoggedIn() {
      console.log(this.user);
      return this.user ? true : false;
    },
  },
};
</script>

<style scoped>
a {
  color: #0060b6 !important;
  text-decoration: none !important;
}
.btn-Login {
  font-family: "Open Sans", sans-serif;
  color: #fff;
  text-transform: initial;
  display: inline-block;
  box-sizing: border-box;
  outline: none;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  white-space: nowrap;
  padding: 8px;
  line-height: 1.42857;
  background-color: #ff1414 !important;
  border: none;
}
</style>

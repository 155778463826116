import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false

const server = axios.create({
  baseURL: process.env.VUE_APP_REMOTE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
})

Vue.prototype.$http = server;

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

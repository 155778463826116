<template>
  <v-container>
    <section class="header primary">
      <h1></h1>
    </section>

    <v-row>
      <!-- Main content -->
      <v-col
        cols="md-8"
        style="margin-bottom: 12px; margin-top: 20px"
        class="secondary"
      >
       <!-- Image big here  -->
        <v-img
          alt="ipNX | Creating Possibilities"
          style="width: 100%; height: 351px"
          src="../assets/ipnx-banner.png"
        ></v-img>
      </v-col>
      <!-- ./Main content -->
      <v-col cols="md-4">
        <v-card style="box-shadow: none">
          <v-card-title>
            <v-layout align-center justify>
              <h2>Forgot Password</h2>
            </v-layout>
          </v-card-title>
          <v-card-text>
            <p>
              Enter your customer number and we'll send a reset link to the
              registered email.
            </p>
            <v-form>
              <v-text-field
                required
                outline
                label="Customer Number"
                type="text"
                v-model="username"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="#d73630"
              style="width: -webkit-fill-available; color: white"
              large
              :disabled="!isValid"
              @click="send_reset"
            >
              Send Password Reset
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="js">
  export default {
    name: 'ForgotPassword',
    data: () => ({
      username: ''
    }),
    computed: {
      isValid() {
      return this.username.length >= 5
      }
    },
    methods: {
      send_reset() {
        const data = {
         username: this.username
        }
       // POST to password reset endpoint.
       this.$http
        .post('/reset-password', data)
        .then((resp) => {
          this.loading = false
          if (resp.data.status == false) {
            // this.snack(resp.data.message, 'error')
           this.$root.NotificationBar.show({color:"warn", icon: "mdi-alert", message: 'Something went wrong. Please try again later', showSnackbar: true})
           return false;
          }
          else {
            this.$root.NotificationBar.show({ color:"success", icon: "mdi-check", message: 'Please check your email for the password reset.', showSnackbar: true})
            this.$router.push("/login");
          }
        })
        .catch(() => {
          this.loading = false
          // this.snack('A network error occured', 'error')
         this.$root.NotificationBar.show({icon: "mdi-alert", message: 'Something went wrong. Please check your inputs and try again', showSnackbar: true})        })
      }
    }
  }
</script>

<style scoped>

</style>

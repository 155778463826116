
<template>
  <v-snackbar
    v-model="showSnackbar"
    :timeout="timeout"
    :multi-line="true"
    top
    right
  >
    <v-icon left>{{ icon }}</v-icon
    >{{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: "NotificationBar",
  data: () => ({
    showSnackbar: false,
    timeout: 3000,
    message: "",
    icon: "mdi-check",
    color: ""
  }),
  methods: {
    show(data) {
      this.message = data.message;
      this.showSnackbar = data.showSnackbar;
      this.icon = data.icon
      this.color = data.color
    },
  },
};
</script>
      
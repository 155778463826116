<template>
  <v-container class="text-center">
    <v-card class="outlined primary">
      <v-list-item>
        <v-list-item-content two-line>
          <div class="overline mb-4">
            <v-list-item-subtitle> WALLET BALANCE </v-list-item-subtitle>
            <v-list-item-title
              style="color: green; font-size: 1.4rem; margin-top: 12px"
            >
              NGN {{ currency(wallet_balance) }}
            </v-list-item-title>
          </div>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list-item>
        <v-list-item-content>
          <div class="balance-info">
            <p>
              Please make a transfer to the account below to top up your wallet
            </p>
            <p class="align-left">
              Bank: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<strong>
                {{ wallet_bank }}
              </strong>
            </p>
            <p class="align-right">
              Account: <strong> {{ wallet_number }} </strong>
            </p>
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-container>
</template>

<script lang="js">
  export default  {
    name: 'Wallet',
    props: [],
    mounted () {
      // do something when mounted
      // TODO: Localstorage is null when this component mounts.
      const customer = JSON.parse(localStorage.getItem('customer'));

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+customer.auth_token
      }
      this.$http
      .post('/wallet-balance',
        JSON.stringify({ "clientID": customer.clientID}), {headers: headers})
      .then((resp) => {

        console.log("resp:", resp);
        console.log("resp.data:", resp.data);
        
        this.wallet_balance = resp.data.data.wallet_balance;
        this.wallet_bank = resp.data.data.wallet_bank;
        this.wallet_number = resp.data.data.wallet_number;
    }).catch((err) => {
      console.log("ERROR "+err);
      // this.$router.push('/login');
      // localStorage.removeItem('customer');
      return
    })

    },
    data () {
      return {
        wallet_balance: '',
        wallet_bank: '',
        wallet_number: ''
      }
    },
    methods: {
      currency (amount) {
        // format numbers into currency.
        return new Intl.NumberFormat("en-US").format(amount);
      }
    },
    computed: {

    }
}
</script>
<style scoped>
.balance-info > p {
  font-size: 0.89em;
}
</style>

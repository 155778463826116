<template lang="html">
  <v-container>
    <section class="dashboard">
      <h1>Invoices</h1>
    </section>
  <v-row>
    <!-- Main content -->
    <v-col cols="md-9 sm-12">
      <div v-for="(invoice, index) in invoices" :key="index">
      <v-card elevation="1" :to="{ name: 'invoice-item', 
          params: {invoice_number: invoice.invoice_id} }">
        <div>
          <v-card-title> 
            Invoice {{invoice.invoice_id}} 
          </v-card-title>
          <v-card-subtitle> Due on {{invoice.invoice_date}} </v-card-subtitle>
          <v-card-actions>
            <p></p>
          </v-card-actions>
        </div>
      </v-card>
      </div>
    </v-col>
    <!-- ./Main content -->

    <!-- Wallet content -->
    <v-col cols="md-3 sm-12" class="primary text-center">
      <Wallet />
    </v-col>
    <!-- ./Wallet content -->
  </v-row>
  </v-container>
</template>

<script lang="js">
  import Wallet from './Wallet.vue';
  
  export default  {
    name: 'dashboard',
    components: {
      Wallet
    },
    props: [],
    mounted () {
      const customer = JSON.parse(localStorage.getItem('customer'));          

      console.log("customer:", customer);

      if (customer == null) {
        this.$router.push('/login');
        localStorage.removeItem('customer');
        return
      }
      else {

console.log("enetered:2");

        const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+customer.auth_token
      }
        this.$http
      .post('/invoices', customer, {headers: headers})
      .then((resp) => {
        // TODO: How to deal with expired token? 
        // console.table(resp);
        if (!resp.data.status) 
        {
         this.$router.push('/login');
         localStorage.removeItem('customer');
         return
        }
      this.invoices = resp.data.data;
    }).catch((err) => {
      this.$root.NotificationBar.show({icon: "mdi-alert", message: 'Something went wrong. '+err, showSnackbar: true})
      
    })
      }

    },
    data () {
      return {
        invoices: '',
        invoice_payments: ''
      }
    },
    methods: {

    },
    computed: {

    }
}


</script>

<style scoped lang="scss">
.v-card {
  margin-bottom: 25px;
  padding: 15px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
</style>

<template>
  <v-app>
    <NotificationBar ref="NotificationBar" />
    <v-app-bar app class="secondary" flat>
      <v-img
        class="mx-2 navbar"
        src="https://self-service.ipnxnigeria.net/assets/logo-9aa81cfa7753fbf69013bc8b793b15f4b02e5e559ab5c8a85c38702cef776598.jpg"
        contain
        style="width: 48px; height: 40px"
      ></v-img>
      <v-spacer></v-spacer>
      <AccountButton> </AccountButton>
    </v-app-bar>
    <v-main app class="primary">
      <router-view></router-view>
    </v-main>
    <v-footer app> </v-footer>
  </v-app>
</template>

<script>
import Dashboard from "./components/Dashboard";
import InvoiceItem from "./components/InvoiceItem";
import Wallet from "./components/Wallet";
import AccountButton from "./components/AccountButton.vue";
import NotificationBar from "./components/NotificationBar.vue";

export default {
  name: "App",

  components: {
    // eslint-disable-next-line vue/no-unused-components
    Dashboard,
    // eslint-disable-next-line vue/no-unused-components
    InvoiceItem,
    // eslint-disable-next-line vue/no-unused-components
    Wallet,
    AccountButton,
    // eslint-disable-next-line vue/no-unused-components
    NotificationBar,
  },

  data: () => ({
    //
  }),
  mounted() {
    this.$root.NotificationBar = this.$refs.NotificationBar;
  },
};
</script>
<style scoped>
.app {
  font-family: "Open Sans", sans-serif;
}
.form-control {
  caret-color: black;
}
</style>